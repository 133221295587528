.contact-container {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left;
    padding: 30px;
    border-radius: 8px;
  }
  .contactImage{
    /* height: 60px; */
    width: 50%;
  }
  .contactImage>img{
    object-fit: contain;
    height: 100%;
    width: 100%;
  }
.contactPageHeading{
  color: #900C27;
}
#emailContact:hover{
  color: #ffaa00 !important;
}
  .input-row {
    margin-bottom: 15px;
  }
  
  .input-field {
    width: 100%;
    padding: 15px;
    margin: 5px 0;
    border: none;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;

  }
  #message{
    border: none !important;
    width: 100%;
    padding: 10px;
    margin: 5px 0;
    margin-bottom: 5vh;
    border: none;
    border-radius: 4px;
    box-shadow: rgba(119, 116, 116, 0.45) 0px 25px 20px -20px;
  }
  .send-button {
    background-color: #B80334;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
  }
  
  .send-button:hover {
    background-color: #F6C667;
    color: #B80334;
    outline: 2px solid white;
  }
  .input-field:focus{
    border: none !important;
  }

  .response-message {
    color: #228B22;
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
  }
  @media (max-width: 768px) {
    .contactImage{
      display: none;
    }
  }