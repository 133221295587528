/* src/Footer.css */

.footer{
    background-color: rgba(255, 0, 0, 0.5);
    padding: 20px;
  }
  footer{
    background-image: linear-gradient(to right, rgb(0, 0, 0), #B80334 60% );
  }

  .cursive-heading {
    color: #ffb319;
  }
  
  .footer-text {
    color: white;
  }
  
  .list-unstyled li{
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .list-unstyled li a:hover{
      color: #ffb319 !important;
  }
  .list-unstyled li a{
    text-decoration: none !important;
  }
  .footer-link:hover{
    color: #ffb319 !important;
  }

  .quoteFooter{
    color: #ffffffe3;
    padding-left: 50px;
  }
  .quoteFooter span{
    color: rgba(177, 175, 175, 0.925);
  }

  @media (max-width:768px) {
    .quoteFooter{
      padding-left: 0;
    }
  }