.gallery-container {
  background-image: linear-gradient(#ffffff, #cc9191ad);

  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px;
}

.cursive-heading {

  font-family: MadelynFill;
  font-weight: 800;
  text-align: center;
  margin-bottom: 40px;
  color: #ffb319;
}
.border-rounded{
  border-radius: 20px;
}
.img-fluid {
  width: 100%;
  height: 400px;
  object-fit: contain;
  transition: all ease-in 0.2s;
}

.overflow-hidden{
  position: relative;
}
.img-fluid:hover{
  transform: scale(1.1);
}
.img-fluid:hover img-fluid-inner{
  opacity: 1;
} 
.img-fluid-inner{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 95%;
  background-color: rgba(228, 32, 32, 0.3); 
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.overflow-hidden:hover .img-fluid-inner {
  opacity: 1;
}
.overflow-hidden:hover img-fluid {
  transform: scale(1.1);
}

@media (max-width:768px) {
  .img-fluid {
    object-fit: cover;
  }
  .gallery-container{
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}