*{
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: "Comme", sans-serif;
}


.cursive-heading{
  /* font-family: MadelynFill !important; */
  font-weight: 500 !important;
  font-size: 3.5rem !important;
}
.cursive-heading2{
  color: #ffb319;
  font-weight: 300 !important;
  font-size: 3.5rem !important;
  font-family: "Comme", sans-serif;
}
.navbar-header {
    display: flex;
    align-items: center;
  }
  
  .logo-container {
    display: flex;
    align-items: center;
  }
  
  .logo {
    height: 10vh;
    width: 8vw;
    margin-right: 10px;
  }
  
  .brand-text-container {
    display: flex;
    flex-direction: column;
  
  }
  
  .brand-text {
    font-weight: bold;
    font-size: 18px;
    margin-left: 10px;
    color: #080808;
  }
  
  .foundation-text {
    font-size: 16px;
    font-weight: bold;
    margin-left: 10px;
  }
  
  .navbar-nav .nav-link {
    padding: 0.5rem 1rem;
    color: #ffffff;
    font-weight: 500;
    transition: color 0.3s ease;
  }
  
  .navbar-nav .nav-link:hover {
    color: blue;
    text-decoration: underline;
  }
  
  .navbar-nav .nav-link.active {
    color: blue !important;
    text-decoration: underline;
  }
  
  .submenu {
    position: absolute;
    background-color: #e9ecef;
    list-style-type: none;
    padding: 0;
    margin: 0;
    border: 1px solid #ccc;
    z-index: 3;
     border-radius: "10px"
  }
  
  .submenu li {
    padding: 10px;
  }
  
  .navbar-light{
    background-color: rgba(110, 105, 105, 0) !important;
  }
  
  .navbar-light .navbar-nav .nav-link {
    color: #000;
  }
  
  .navbar-nav .nav-item {
    color: #000000;
  }
  
  @media (max-width: 768px) {
    .cursive-heading2{
      font-size: 2.5rem !important;
    }
 
  }