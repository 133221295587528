
.my-journey-transparent-red {
  position: relative;
  background-image: url('../images/IMG\ 63a.jpg');
  background-size: cover;
  background-position: center top;
  z-index: 1;
  background-repeat: no-repeat;
  padding: 50px 0;

}

.my-journey-transparent-red::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(#fffffffa , #ffffffd3 );
  z-index: -2;
}

.my-journey-styled-container {
  position: relative;
  z-index: 10;
}

.my-journey-cursive-heading {

  font-family: MadelynFill;
  font-weight: 500;
  font-size: 3.5rem !important;
  color: #ffb319;
  margin-bottom: 20px;
}

.monospaced-text {
  line-height: 1.8;
  color: #900C27;
}

  
  .cursive-heading {
    color: #ffb319;
    text-align: center;
    margin-bottom: 20px;
  }
  
@media (max-width: 768px) {
  
  .my-journey-transparent-red {
    /* height: 120vh; */
  }
}