* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.header-wrapper{
  position: relative;
  background-image: url('../images/portfolio.jpg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1;
  padding: 40px 0;
}

.header-wrapper::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(#992040b9 , #900C27 );
  z-index: -2;
}

.cursive-heading {

  font-family: MadelynFill;
  font-weight: 500;
  font-size: 3.5rem;
  color: #ffb319;
  margin-bottom: 20px;
}

.header-box{
  position: relative;
  z-index: 100;
}
.header-text{
  line-height: 1.8;
}

.footer-container {
  background-image: url('../images/world map.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative; /* Add this line */
  z-index: 1;
  padding: 50px 0;
}

.footer-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: linear-gradient(#992040b9, #900C27); */
  background: linear-gradient(#b80333b0, #d80a41c2);
  z-index: -2;
}


/* Responsive styles */

@media (max-width: 768px){

  .header-wrapper{
    background-size: cover; 
    padding: 80px 0;
  }

}