.blog-container {
    padding: 20px;
  }
  
  .blog-heading {
    /* font-family: 'Cursive', sans-serif; */
    color: black;
    text-align: center;
    margin-bottom: 20px;
  
    padding: 10px;
    border-radius: 8px;
    color:#ffb319;
  }
  
  .blog-description {
    text-align: center;
    margin-bottom: 40px;
    color: rgb(77, 46, 46);
  }
  
  .blog-col {
    margin-bottom: 20px;
    overflow: hidden;
  }
  
  .blog-image {
    width: 100%;
    height: 300px;
    object-fit:unset;
    border-radius: 8px;
  }
  
  .blog-content {
    background-color: #B80334;
    padding: 15px;
    border-radius: 8px;
    text-align: center;
  }
  
  .blog-title {
    font-weight: 500;
    letter-spacing: 3px;
    color: #ffb319;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .blog-title2 {
    color: #ffb319;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .blog-content p {
    display: -webkit-box;
    line-clamp: 3;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.blog-content p.expanded {
    -webkit-line-clamp: unset;
    line-clamp: unset;
    overflow: visible;
}

.text-justify{
  text-align: justify;
}
  .read-more-button {
    background-color: #900C27;
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 3rem;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
  }
  
  .read-more-button:hover {
    /* background-color: #a11632; */
    background-color: #ffb219e8;
    color: #B80334;
  }
  
  .link-margin{
    margin: 40px 20px !important;
  }

  @media (max-width: 1200px) {
    .read-more-button {
      margin: auto;
    }
  }