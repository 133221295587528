
.backTopButton{
    display: none;
    height: 50px;
    width: 50px;
    background-color: #F6C667;
    border: none;
    border-radius: 50%;
    padding: 3px;
    position: fixed;
    right: 20px;
    bottom: 35px;
    font-size: 13px;
    z-index: 10;
    transition: opacity 0.3s ease-in-out; 
}

.backTopButton:hover{
    background-color: #ffaa00;
}

.backTopButton.visible{
    display: block;
}

@media (max-width: 768px) {
    
.backTopButton{
    right: 20px;
    bottom: 13%; 
}
}