:root {
  --bs-navbar-color: #ffffff !important;
}

.imagebg {

  background-image: url('../images/IMG\ 63.png');

  background-size: cover;
  background-position: left center;
  background-repeat: no-repeat;

  height: 115vh;
  width: 100%;
}

.imagebg {
  position: relative;
  min-height: 100vh;
  background-size: cover;
  background-position: center;
}

.loader-background {
 z-index: 1000;
  --d:22px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  color: #25b09b;
  box-shadow: 
    calc(1*var(--d))      calc(0*var(--d))     0 0,
    calc(0.707*var(--d))  calc(0.707*var(--d)) 0 1px,
    calc(0*var(--d))      calc(1*var(--d))     0 2px,
    calc(-0.707*var(--d)) calc(0.707*var(--d)) 0 3px,
    calc(-1*var(--d))     calc(0*var(--d))     0 4px,
    calc(-0.707*var(--d)) calc(-0.707*var(--d))0 5px,
    calc(0*var(--d))      calc(-1*var(--d))    0 6px;
  animation: spin 1s infinite steps(8);
  position: absolute;
  top: 50%;
  left: 50%;
}


@keyframes spin {
  100% {transform: rotate(1turn)}
}



.portfolio-navbar {
  background-color: #f8f9fa;
}

.navbar-toggler-icon-custom {
  border-color: white;
}

.navbar-toggler-icon-custom .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23ffffff' stroke-width='4' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}


.brand-name {
  font-family: MadelynFill;
  font-weight: 500;
  font-size: 4.2rem;
  color: #ffb319 !important;
  margin-left: 30px;
  margin-right: 0 !important;
}

.brand-name:hover {
  color: #B80334 !important;
  transition: all ease-in-out 0.4s;
}

.navbar-nav .nav-link {
  font-size: 1.1rem;
  /* Increase font size for links */
  color: #ffffff !important;
  /* Default color */
  margin-right: 10px;
  /* Right margin for spacing */
}

.navbar-nav .nav-link:hover {
  color: #F6C667 !important;
  /* Change color on hover */
  text-decoration: none;
}

.navbar-nav .nav-link:focus {
  color: #F6C667 !important;
  text-decoration: none;
}

.social-icons {
  display: flex;
  align-items: center;
}

.social-icons .nav-link {
  font-size: 1.2rem;
  margin-right: 5px;

}
.bannerTextdesc {
  font-weight: 700;
  font-size: 2.6;
  letter-spacing: 1.5px;
}

@media (max-width: 768px) {
  .social-icons .nav-link {
    font-size: 1.7rem;
  
  }
}

.social-icons .nav-link:hover {
  color: #F6C667;
  /* No hover effect */
}

.social-icons .nav-link:active{
  color: #F6C667 !important;
}
.social-icons .nav-link:focus{
  color: #F6C667 !important;
}

.youtube-icon {
  color: #FF0000;
  /* YouTube red color */
}

.instagram-icon {
  color: #ffffff;
  /* Instagram gradient color */
}

.twitter-icon{
  color: #ffffff !important;
}
.twitter-icon:hover{
  color: #F6C667 !important;
}
.fbicon {
  /* color: #4285F4; */
  color: #ffffff;
}

/* header */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.header {
  
  background-image: url('../images/tempimgportfolio\ -\ Copy.jpg');
  background-size: cover;
  background-position: cover;
  background-repeat: no-repeat;
 
  height: 60vh;
 
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 20px;
  position: relative;
}

.header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
 
  z-index: 1;
}

.header-content {
  position: relative;
  z-index: 2;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 20vh;
  padding: 20px;
}

.text-box {
  background: transparent !important;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: right;
  max-width: 400px;
  margin-left: auto;
}

.text-box h3,
.text-box h5 {
  margin-bottom: 10px;
}

.join-button {
  background-color: #B80334;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.join-button:hover {
  background-color: #d82555;
}

/* Responsive styles */
@media (max-width: 480px) {
  .text-box {
    text-align: center;
    margin: 0 auto;
  }

  .imagebg {
    background-size: cover;
    background-position: left;
    height: 100vh;
  }

  .header-content {
    margin: 0 !important;
    margin-top: 65vh !important;
    padding: 0 23px !important;
    background-color: #1818189f;
  }

  .header-wrapper {
    /* height: 120vh; */
  }

  .brand-name {
    margin-left: 0;
    /* Left margin */
    padding-left: 0;
  }

  #basic-navbar-nav {
    background-color: #1f1f1f9d;
  }

  .navbar-toggler {
    margin-right: 8px !important;
    /* color: #ffffffbe; */
  }


}

@media (max-width: 380px) {
  .imagebg {
    background-size: cover;
    background-position: left;
    /* height: 60vh; */
    height: 101vh;
  }

}