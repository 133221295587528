@font-face {
    font-family: 'Madelyn';
    src: url('./Madelyn.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Madelyn';
    src: url('./MadelynFill.otf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  